/* Import the Glacial Indifference font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Glacial+Indifference&display=swap');

/* Apply the Glacial Indifference font to the body element to inherit in other elements */
body {
  font-family: 'Glacial Indifference', sans-serif; /* cursive changed to sans-serif for better readability */
}

.logo-container {
    text-align: center;
    margin-bottom: 20px; /* Adjust as needed */
}

.navbar-logo {
    width: 60px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
}

/* Bootstrap overrides */
.navbar {
    border-top: 2px dashed #000;
    border-bottom: 2px dashed #000;
}

.navbar-brand {
  font-weight: #700fab;
}

.navbar-nav .nav-link {
  font-size: 1.2em;
}

/* Right align the auth links */
.navbar-collapse {
  justify-content: space-between;
}

.navbar-nav {
  flex-direction: row;
}

.navbar-nav .nav-link.active {
    font-weight: bold;
    color: #FFA500; /* Example color for active state */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .navbar-nav .nav-link {
    padding: 0.5em 1em; /* Increased padding for better touch targets */
  }

  .navbar-brand {
    flex-grow: 1; /* Ensures the brand is left-aligned and other items can be right-aligned */
  }

  .navbar-collapse {
    flex-grow: 1;
  }
}
