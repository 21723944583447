/* Add this to your ContactForm.css */

.centered-form .logout-button {
    background-color: rgb(0, 0, 0); /* Black background to match other buttons */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    display: block; /* Block display to take the full width of the form */
    width: 100%; /* Make the button expand to the full width of its container */
    margin-top: 20px; /* Add some margin at the top */
    margin-bottom: 20px; /* Add some margin at the bottom */
}

/* Ensure high specificity for the delete button normal state */
.centered-form .delete-account-button {
    background-color: rgba(255, 0, 0, 0.232); /* Red background for delete button */
    color: rgb(6, 6, 6);
    border: none;
    margin-top: 30px; /* Add space if needed */
}

/* Ensure high specificity for the delete button hover state */
.centered-form .delete-account-button:hover {
    background-color: rgb(200, 0, 0); /* Darker red on hover */
    color: white; /* Ensures text color is white */
}

.logout-button {
    margin-top: 20px; /* Spacing above the logout button */
}

/* You can use this to isolate the delete button further */
.centered-form .delete-account-button {
    margin-top: 30px; /* More space above the delete button */
    border: none;
}

.centered-form .logout-button {
    background-color: rgb(0, 0, 0); /* Black background to match other buttons */
    color: white;
    border: none;
    display: block; /* Ensure it's a block-level element */
    width: 100%; /* Full width */
}

/* Adjust the logout button hover effect as needed */
.centered-form .logout-button:hover {
    background-color: rgb(115, 8, 101); /* Change color on hover */
}
