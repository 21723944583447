.spotify-iframe {
    width: calc(100% - 30px);
    height: 352px;
    margin: 15px;
    border-radius: 12px;
  }
  
  .platforms-link-container {
    text-align: center;
    margin: 20px 0;
  }
  
  .platforms-link {
    text-decoration: none;
    color: black;
  }
  
  .platforms-link:hover {
    font-weight: bold;
  }
  
  .dashed-line {
    border-top: dashed 2px #ccc;
    margin-top: 20px;
  }
  
  .filter-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .toggle-filters-row {
    align-self: flex-start;
    margin-bottom: 10px;
  }
  
  .show-filters {
    padding: 5px 10px;
    border-radius: 4px;
  }

  .show-filters:hover {
    text-decoration: none;
    /* Ensures there's no underline on hover */
    font-weight: bold;
    /* Makes the text bold on hover */
  }
  
  .filter-bar,
  .subcategory-filter-bar {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px 0;
  }
  
  .main-category-filter-bar {
    margin-bottom: 5px;
  }
  
  .subcategory-filter-bar {
    border-top: 1px solid #cccccc;
    padding-top: 5px;
    margin-top: 5px;
  }
  
  .filter-text {
    cursor: pointer;
    padding: 5px 10px;
  }
  
  .filter-text.active,
  .category-button.active {
    font-weight: bold;
    color: #000000;
    text-decoration: none; /* This will remove underline from active filters */
  }
  
  .music-grid-item {
    padding: 2px; /* Reduced padding for a smaller box */
    margin-bottom: 10px; /* Reduced margin for less space between rows */
  }
  
  @media (max-width: 768px) {
    .show-filters,
    .category-button {
      width: 100%;
    }
  
    .filter-bar {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  