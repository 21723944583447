.event {
    text-align: center; /* Center align the content of each event */
    margin-bottom: 20px; /* Space between each event item */
}

.event-image-container {
    /* Container for the image to control size and centering */
    width: 100%; /* Use full width for smaller screens or adjust as needed */
    max-width: 700px; /* Maximum width of the image container */
    margin: 0 auto; /* Center the container */
    overflow: hidden; /* Hide overflow in case of larger images */
}

.event-image {
    width: 100%; /* Responsive image width to fill the container */
    max-height: 500px; /* Maximum height of the image */
    object-fit: cover; /* Cover the area without stretching */
    transition: transform 0.3s ease; /* Smooth transition for enlarging effect */
}

.event-image:hover {
    transform: scale(1.25); /* Enlarge the image on hover */
}

.event .event-name {
    color: black; /* Sets the text color to black */
    text-decoration: none; /* Removes the underline */
    font-size: 1.25em; /* Adjusts the font size for larger screens */
}

.event .event-name:hover {
    font-weight: bold; /* Makes the text bold only on hover */
}

/* Responsive adjustments for typical iPhone sizes */
@media (max-width: 428px) { /* iPhone 13 Pro Max and smaller */
    .event-image {
        max-width: 300px; /* Adjust image size for smaller iPhone screens */
    }

    .event .event-name {
        font-size: 1.15em; /* Slightly smaller text size for iPhone screens */
    }
}

@media (max-width: 375px) { /* iPhone 12, 12 Pro, 11 Pro, XS, X, and similar sizes */
    .event-image {
        max-width: 250px; /* Further reduce image size for mid-sized iPhones */
    }

    .event .event-name {
        font-size: 1.1em; /* Adjust text size for mid-sized iPhone screens */
    }
}

@media (max-width: 320px) { /* iPhone 5/SE and smaller */
    .event-image {
        max-width: 200px; /* Smallest image size for very small iPhone screens */
    }

    .event .event-name {
        font-size: 1em; /* Smaller text size for very small iPhone screens */
    }
}
