/* Ensure that title scales down on smaller screens and is centered */
.title {
  font-size: 19rem;
  /* Adjust size for larger screens */
  text-align: center;
}

.welcome-message {
  font-size: 1.5rem;
  /* Sets the size of the font */
  color: #333;
  /* Sets the text color */
  margin: 10px 0;
  /* Adds space above and below the message */
  text-align: center;
  /* Centers the text within the message */
}

.see-all-link:hover {
  font-weight: bold;
  /* Bold on hover */
  color: black;
  /* Ensure text color is black on hover */
  text-decoration: none;
  /* Ensure no underline on hover */
}

/* Apply the same hover effects to all platform links */
.platforms-link-container,
.see-all-container {
  text-align: center;
  margin: 20px 0;
  cursor: pointer;
  /* Change cursor to indicate it's clickable */
}

.see-all-link,
.platforms-link {
  font-size: 1.5rem;
  /* Larger font size */
  color: black;
  /* Explicitly set to black */
  text-decoration: none;
  /* Removes underline */
}

.see-all-link:hover,
.platforms-link:hover {
  font-weight: bold;
  /* Bold on hover */
  color: black;
  /* Ensure text color is black on hover */
  text-decoration: none;
  /* Ensure no underline on hover */
}

/* Dashed line style */
.dashed-line {
  border-top: dashed 2px #000000;
  margin-top: 20px;
}

/* Styling for the YouTube video container to be centered */
.youtube-container {
  text-align: center;
}


@media (max-width: 576px) {

  /* For extra small devices */
  .title {
    font-size: 7rem;
    /* Smaller font size for mobile screens */
  }

  .welcome-message {
    font-size: 1rem; /* Smaller font size on smaller screens */
  }
}