.music-detail .music-name {
    font-size: 9vw; /* Responsive font size */
    font-weight: bold; /* Makes the text bold */
    margin: 20px 0; /* Adds space above and below the music name */
    text-align: center; /* Centers the text horizontally */
  }
  
  .music-detail .name-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; /* Ensure full width for center alignment */
  }
  
  .music-detail .music-image-container {
    display: flex;
    justify-content: space-between; /* Distributes space between images evenly */
    align-items: center; /* Aligns images vertically */
    flex-wrap: wrap; /* Allows images to wrap on smaller screens */
    gap: 10px; /* Optional space between images */
  }
  
  .music-detail img.music-image {
    width: 100%; /* Adjusted to take the full width of the parent container */
    height: auto; /* Maintains aspect ratio */
    object-fit: cover; /* Adjust as needed, 'cover' will fill the height */
    max-width: calc(50% - 5px); /* Maximum width to maintain the aspect ratio */
  }
  
  /* For medium devices (tablets) */
  @media (max-width: 992px) {
    .music-detail .music-name {
      font-size: 7vw; /* Smaller font size for medium devices */
    }
  }
  
  /* For small devices (landscape phones, 576px and up) */
  @media (max-width: 768px) {
    .music-detail .music-image-container {
      justify-content: center; /* Centers images on smaller screens */
    }
  
    .music-detail img.music-image {
      max-width: 80%; /* Slightly smaller images on small screens */
      margin: 0 auto; /* Center images */
    }
  }
  
  /* For extra small devices (phones, less than 576px) */
  @media (max-width: 576px) {
    .music-detail .music-name {
      font-size: 7vw; /* Larger font size for readability on small devices */
    }
  
    .music-detail img.music-image {
      max-width: 100%; /* Full width images on extra small screens */
    }
  }
  