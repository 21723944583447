.about-container {
    max-width: 1200px;
    margin: 80px auto; /* Center the container */
  }
  
  .text-container {
    padding: 0 15px;
  }
  
  .about-title {
    margin-bottom: 1em;
    text-align: center;
  }
  
  .img-fluid {
    width: 100%;
    object-fit: cover;
    margin-bottom: 15px;
  }
  
  /* Media Query for iPhones and similar devices */
  @media (max-width: 576px) {
    .about-container {
      margin: 40px auto; /* Smaller margin for small screens */
    }
  
    .img-fluid {
      height: auto; /* Auto-adjustable height */
    }
  
    .text-container, .instagram-container {
      padding: 0 10px; /* Smaller padding for small screens */
    }
  
    .about-title {
      font-size: 1.5rem; /* Smaller font size for small screens */
    }
  }
  
  /* Additional responsive adjustments */
  @media (max-width: 768px) {
    .about-container .row > [class^="col-"] {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  