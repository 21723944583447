.music-name,
.music-name:link,
.music-name:visited {
  /* This targets the <a> element if it has been visited */
  text-decoration: none;
  /* Removes the underline */
  color: black;
  /* Set the text color to black */
  cursor: pointer;
  transition: font-weight 0.2s;
  /* Smooth transition for the font weight */
}

/* Center the text of the music name below the images */
.music-name {
  display: block;
  margin-top: 0.5em;
  /* Adjust the space between the images and the name */
  text-align: center;
  font-size: 1.5em;
  /* Increased font size */

}

.music-name:hover {
  text-decoration: none;
  /* Ensures there's no underline on hover */
  font-weight: bold;
  /* Makes the text bold on hover */
}

.music-container {
  display: flex;
  justify-content: center;
  /* Center the items horizontally */
  align-items: center;
  /* Align items vertically */
  flex-wrap: nowrap;
  /* Prevent the items from wrapping */
  margin: 20px;
  /* Space around the music container */
}

.music-image-container {
  flex: 0 0 auto;
  position: relative;
}

.music-image {
  width: 300px; /* Set a fixed width for all images */
  height: 300px; /* Set a fixed height for all images */
  object-fit: cover; /* Cover ensures that the aspect ratio is maintained while filling the container */
  /* You can use object-fit: contain; if you don't want the images to be cropped */
}

@media (max-width: 768px) {
  .music-container {
    flex-wrap: wrap;
    /* Allow the items to wrap on smaller screens */
  }

  .music-image {
    width: 200px; /* Set a fixed width for all images */
    height: 200px; /* Set a fixed height for all images */
  }
}