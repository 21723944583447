.credits-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 2px dashed #000;
    margin-top: auto; /* This will push the credits to the bottom */
}


.credits-links {
    display: flex;
    align-items: center;
}

.credits-links {
    flex: 2; /* allows it to grow more */
    justify-content: center;
}

.credits-links a, .credits-links span {
    margin: 0 10px;
}

.credits-middle {
    flex: 2; /* Allows this div to grow more */
    text-align: center; /* Centers the content */
}

.credits-title {
    font-size: 1.5em; /* Sets the size of the TAIME.CO text */
    margin-bottom: 0.5rem; /* Adds some space below the title */
}

.credits-author {
    font-size: 0.9em; 
    margin-top: 1rem; 
    margin-bottom: 0.5rem; 
}

.credits-links img {
    height: 40px;
}

/* Responsive styles */
@media (max-width: 768px) {
    .credits-container {
        flex-direction: column;
        align-items: center;
    }

    .credits-middle {
        align-items: center;
        text-align: center;
        margin: 10px 0; /* Adds vertical space between stacked items */
    }
}

@media (max-width: 375px) { /* Specific media query for smaller devices like iPhone */
    
    .credits-middle img {
        height: 15px; /* Smaller logos for smaller screens */
    }

    .credits-middle .credits-links a, .credits-middle .credits-links span {
        margin: 0 5px; /* Less space between icons for smaller screens */
    }

    .credits-middle {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
