/* ContactForm.css */
.center-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align items to the start of the container, reducing the top margin */
    min-height: calc(100vh - 50px); /* Subtract a value from the full height to reduce the top margin */
    padding-top: 50px; /* Add padding to the top inside the flex container */
    padding: 0 15px; /* Maintain existing horizontal padding */
  }
  
  .centered-form {
    width: 100%;
    max-width: 500px; /* Adjust the max-width as needed for your design */
    margin-top: 20px; /* Reduce top margin */
    padding: 15px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
  }

  /* Style for the submit button with black background and white text by default */
.centered-form .submit-button {
    background-color: rgb(0, 0, 0);
    color: white;
    border: none; /* Remove default border */
    border-radius: 5px; /* Optional: rounded corners */
    padding: 10px 20px; /* Padding inside the button */
    font-size: 1em; /* Font size for the button */
    cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
    display: block; /* Block display to take the full width of the form */
    width: 100%; /* Make the button expand to the full width of its container */
    margin-top: 20px; /* Add some margin at the top */
  }
  
  /* Style for the button on hover */
  .centered-form .submit-button:hover {
    background-color: rgb(115, 8, 101);
  }
  
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .center-container {
      align-items: center; /* Center the form on smaller screens */
      min-height: 100vh;
      padding-top: 0; /* Remove additional top padding on smaller screens */
    }
  
    .centered-form {
      margin-top: 10px; /* Adjust top margin on smaller screens if needed */
      padding: 15px;
      box-shadow: none; /* You might want to remove the shadow on smaller screens */
    }
  }
  