.product-container {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Full width to ensure responsiveness */
  max-width: 450px; /* Maximum width of the container */
}

.product-box {
  border: 2px solid #333;
  border-radius: 40px;
  overflow: hidden;
  width: 100%; /* Relative to its parent */
  height: auto; /* Maintain aspect ratio */
  aspect-ratio: 4 / 5; /* Maintain an aspect ratio */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto; /* Center the box in the available space */
}

.product-box img {
  width: 100%;
  height: auto; /* Maintain the aspect ratio */
  object-fit: contain; /* Ensure the image fits without cropping */
}

.product-name {
  text-align: center;
  font-size: 1rem;
  margin-top: 8px;
  color: #333;
  width: 80%; /* Relative to its parent */
}

.product-name:hover {
  text-decoration: none;
  /* Ensures there's no underline on hover */
  font-weight: bold;
  /* Makes the text bold on hover */
}

.product-name a, 
.product-name a:visited { /* Styles for normal and visited states */
  color: inherit; /* This will make the color the same as the parent, removing the blue */
  text-decoration: none; /* Removes the underline */
}

.product-name a:hover,
.product-name a:active { /* Styles for hover and active states */
  color: inherit; /* Keep the color consistent on hover and active states */
  text-decoration: none; /* Optionally, you could add underline on hover if desired */
}

/* Responsive adjustments for smaller devices */
@media (max-width: 576px) {
  .product-container {
    max-width: none; /* Remove the max-width */
  }
  .product-box {
    width: 90%; /* Make the box wider on smaller screens */
  }
}
