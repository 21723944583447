/* ProductDetail.css */

.product-detail .name-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; /* Ensure full width for center alignment */
  }

/* Reset margins and padding for the product detail */
.product-detail {
    margin: 0;
    padding: 0;
}

/* Style for the product name */
.product-detail .product-name {
    font-size: 8vw; /* Responsive font size based on the width of the viewport */
    font-weight: bold; /* This line makes the text bold */
    margin: 20px 0; /* Top and bottom margin */
    text-align: center; /* Centers the text within its container */
    width: 100%; /* Ensures the element spans the full width */
}

/* Center the YouTube or main image horizontally */
.product-detail .youtube-video,
.product-detail .main-image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
}

/* Style for the section that contains the YouTube/image to ensure no top margin */
.product-detail .top-section {
    margin-top: 0;
}

/* Style for request button */
.product-detail .request-button {
    background-color: grey;
    color: black;
    padding: 15px 30px;
    font-size: 1em;
    /* Responsive font size */
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.request-button-container {
    margin-bottom: 20px; /* Adjust the value as needed */
  }

/* Hover effect for request button */
.product-detail .request-button:hover {
    background-color: black;
    color: white;
}

z

/* Style for form controls */
.request-form .form-control {
    margin-bottom: 1em;
}

/* Style for product images */
.product-detail .product-image {
    width: 100%; /* Increase the width to 100% */
    object-fit: cover;
    display: block; /* Ensure the image is displayed as a block element */
    margin: 20px auto; /*Center the image horizontally*/
    aspect-ratio: 4 / 4; /* Maintain an aspect ratio */
    /* Center and add space around images */
}

.product-detail .product-image img {
    width: 100%;
    height: auto;
}

/* Style for product description */
.product-detail .product-description {
    font-size: 2em;
    /* Scalable font size for different devices */
    margin: 20px 0;
}

/* Modal submit button styles */
.modal .modal-submit-button {
    background-color: rgb(0, 0, 0);
    color: white;
}

.modal .modal-submit-button:hover {
    background-color: rgb(115, 8, 101);
    color: white;
}

/* Modal content styles */
.modal-content {
    background-color: #fff;
    border-radius: 0;
    border: none;
}

/* Modal header and footer styles */
.modal-header,
.modal-footer {
    border: none;
}

/* Modal title styles */
.modal-title {
    font-weight: bold;
}

/* Responsive styles for smaller devices like iPhones */
@media (max-width: 768px) {
    .product-detail .product-name {
        font-size: 5em;
        /* Larger font size for small devices */
    }

    .product-detail .product-image {
        width: 95%;
        /* Slightly increase width for small devices */
    }

    .product-detail .product-description {
        font-size: 2em;
        /* Adjust font size for readability on small devices */
    }

    .product-detail .request-button {
        padding: 10px 20px;
        /* Adjust padding for smaller devices */
        font-size: 0.9em;
        /* Adjust font size for smaller devices */
    }
}