/* Events.css */
.events-container {
    margin: 0 auto;
    max-width: 1200px;
    padding: 20px;
}

.events-header {
    margin-bottom: 1rem;
    text-align: center;
    color: #333;
    font-size: 2rem;
}

.events-list {
    list-style: none;
    padding: 0;
}

.event-item {
    border-bottom: 1px solid #eee;
    padding: 15px 0;
}

.admin-panel {
    margin-top: 2rem;
    padding: 1rem;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 4px;
}

/* Responsive layout for smaller screens */
@media (max-width: 768px) {
    .events-container {
        padding: 10px;
    }

    .events-header {
        font-size: 1.5rem;
    }
}
