/* Responsive grid layout for products */
.product-grid-item {
  padding: 5px; /* Add padding to create space between products */
}

.filter-container {
    display: flex;
    align-items: center; /* Align the toggle and the filter bar vertically */
    padding: 10px 0;
  }
  
  .show-filters {
    font-size: 1rem;
    cursor: pointer;
    margin-right: 20px; /* Add some space between the toggle and the filter bar */
    white-space: nowrap;
  }
  
  .filter-bar {
    display: flex;
    flex-wrap: nowrap; /* Prevent the filters from wrapping */
    overflow-x: auto;
  }
  
  .filter-text {
    font-size: 1rem;
    color: black;
    padding: 5px 20px;
    cursor: pointer;
    white-space: nowrap;
  }
  
  .filter-text.active {
    font-weight: bold; /* Make the active filter text bold */
    text-decoration: underline;
  }
  
  /* Optional: Hide scrollbar for a cleaner look */
  .filter-bar::-webkit-scrollbar {
    display: none;
  }

/* Responsive adjustments */
@media (max-width: 576px) {
  /* 2 products per line on extra small devices */
  .product-grid-item {
    flex: 0 0 50%;
    max-width: 50%;
  }

}

@media (min-width: 577px) and (max-width: 768px) {
  /* 3 products per line on small devices */
  .product-grid-item {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }


}

@media (min-width: 769px) {
  /* 4 products per line on medium devices and up */
  .product-grid-item {
    flex: 0 0 25%;
    max-width: 25%;
  }

  
}
