.studio-container {
    max-width: 1200px;
    margin: 70px auto; /* Center the container and standardize margin */
  }
  
  .text-container {
    padding: 0 15px;
  }
  
  .studio-title, .instagram-title {
    margin-bottom: 1em;
    text-align: center;
  }
  
  .img-fluid {
    width: 100%;
    object-fit: cover;
    margin-bottom: 15px;
  }
  
  .instagram-row {
    text-align: center;
  }
  
  /* Media Query for small devices */
  @media (max-width: 576px) {
    .studio-container {
      margin: 40px auto; /* Smaller margin for small screens */
    }
  
    .img-fluid {
      height: auto; /* Height adjusts to screen width */
    }
  
    .text-container, .instagram-row {
      padding: 0 10px; /* Smaller padding for small screens */
    }
  
    .studio-title, .instagram-title {
      font-size: 1.5rem; /* Smaller font size for small screens */
    }
  }
  
  /* Adjustments for medium-sized screens */
  @media (max-width: 768px) {
    .studio-container .row > [class^="col-"] {
      padding-right: 15px;
      padding-left: 15px;
    }
  
    .img-fluid {
      height: 150px; /* Adjusting height for medium screens */
    }
  }
  