/* EventDetail.css */
.event-detail-container {
    text-align: center; /* Center align the entire content */
    padding: 20px; /* Add some padding around the content */
    max-width: 800px; /* Maximum width of the container */
    margin: 0 auto; /* Center the container in the page */
}

.event-detail-container img {
    width: 100%; /* Make images responsive */
    max-width: 500px; /* Maximum width of images */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 20px; /* Space below each image */
    border-radius: 10px; /* Optional: Rounded corners for images */
}

.event-detail-heading {
    font-size: 1.5em; /* Larger text for headings */
    color: #333; /* Dark grey color for text */
    margin-bottom: 10px; /* Space below each heading */
}

.event-detail-text {
    font-size: 1.2em; /* Slightly larger text for better readability */
    color: #666; /* Lighter grey color for text */
    margin-bottom: 20px; /* Space below each piece of text */
}

.event-detail-link {
    display: inline-block; /* Makes the link block-level for margin */
    margin: 10px 0; /* Space above and below the link */
    padding: 10px 20px; /* Padding inside the link for better touch area */
    background-color: #000000; /* Example background color */
    color: white; /* Text color */
    text-decoration: none; /* Removes underline */
    border-radius: 5px; /* Rounded corners */
    font-size: 1.5em; /* Adjust to your preference */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.event-detail-link:hover {
    background-color: #a019d0; /* Darker shade on hover */
}

/* Responsive adjustments for iPhone and other mobile devices */
@media (max-width: 768px) {
    .event-detail-container {
        padding: 10px; /* Reduce padding on smaller screens */
    }

    .event-detail-container img {
        max-width: 300px; /* Reduce maximum width of images */
    }

    .event-detail-heading,
    .event-detail-text {
        font-size: 1em; /* Smaller font size for smaller screens */
    }

    .event-detail-link {
        font-size: 1.2em; /* Adjust link font size */
        padding: 8px 16px; /* Adjust padding */
    }
}

/* Further adjustments for very small screens, like older iPhones */
@media (max-width: 480px) {
    .event-detail-container img {
        max-width: 100%; /* Full width images */
    }
}
