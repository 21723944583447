.app-container {
    min-width: 100%;
    min-height: 100vh;
}

.App {
    text-align: center;
  }
  
.App-logo {
height: 700px;
width: 700px;
}

.App-header {
background-color: #fff;
min-height: 50vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
color: white;
}

.App-link {
color: #61dafb;
}

@keyframes App-logo-spin {
from {
    transform: rotate(0deg);
}
to {
    transform: rotate(360deg);
}
}

.title {
font-family : 'glacial-indifference', cursive;
font-size: 250px;
}
