body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Glacial Indifference', Arial; /* Apply Glacial Indifference font to all elements */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  background-color: white;
  color: black;
  max-height: 50px;
}

@font-face {
  font-family: "glacial-indifference";
  src: local("glacial-indifference"),
   url("./font/glacial-indifference/GlacialIndifference-Bold.otf") format("truetype");
  font-weight: bold;
}

.player-wrapper {
  width: 1000px; 
  height: auto; 
}
.react-player {
  padding-top: 5%; 
  position: relative; 
}

@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Quicksand', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Flex container that wraps the entire app and credits */
.flex-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Main content wrapper to ensure it fills the space and pushes credits down */
.flex-content {
  flex-grow: 1;
  overflow-y: auto; /* Allow vertical scrolling */
}

/* Credits always at the bottom */
.credits {
  flex-shrink: 0;
}

.flex-content {
  overflow-y: auto; /* Adds scroll only if needed */
}

body, html {
  overflow-x: hidden; /* It's okay to hide horizontal overflow if you're not horizontally scrolling */
  overflow-y: visible; /* Ensure vertical overflow is not hidden */
}